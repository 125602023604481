import * as React from "react";
import api_url from "./ApiUrl";
import { CustomBreadcrumb } from './CustomBreadcrumb';
import { Create } from 'react-admin';
import { List, ListActions } from '@react-admin/ra-enterprise';
import JWT from "jsonwebtoken";
import { Tooltip, Typography } from '@material-ui/core';

import {
  useState,
  useEffect,
} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox
} from "@material-ui/core";

const roleData = [];
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const CountrySetting = (props) => {
  const [countryData, setCountryData] = useState({});
  useEffect(() => {
    getCountryLogWithFetch();
  }, []);


  const getCountryLogWithFetch = async () => {
    let login_token = localStorage.getItem("auth");
    let apitoken = JWT.sign({ url: api_url() }, "eyJhbGciOJIUzI1NiIsInR5cCI6IkpXVCJ9eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2Mj5MDIyfQSflKxwRJSMeKF2QT4fwpMeJf36POk6yJVadQsw5c", {
      algorithm: "HS512",
    });
    const request = new Request(api_url() + "/get_countrydata", {
      method: "POST",
      body: JSON.stringify({ role_type: "editor" }),
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${login_token}`,
        'xelxtoken': apitoken
      }),
    });
    const response = await fetch(request);
    const countryJsonData = await response.json();
    setCountryData(countryJsonData);
  };

  const [checked, setChecked] = useState();
  const handleChange = async (event) => {
    //console.log(event.target.checked);
    let click_id = event.target.id;
    let click_status = event.target.checked;
    let login_token = localStorage.getItem("auth");
    const request = new Request(api_url() + "/update_country_status", {
      method: "POST",
      body: JSON.stringify({ id: click_id, status: click_status }),
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${login_token}`,
      }),
    });
    const response = await fetch(request);
    const jsonData = await response.json();
    getCountryLogWithFetch();
  };
  //const classes = useStyles();
  const EditorCountryActions = (props) => (
    <ListActions  {...props} breadcrumb={<CustomBreadcrumb variant="actions" />} exporter={false} />
  )
  return (
    <Create {...props} actions={<EditorCountryActions />} title="Country list">
      <TableContainer component={Paper} className="custom_role_table" style={{ marginTop: '0px', border: '0px' }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>SN</TableCell>
              <TableCell>Country</TableCell>
              <TableCell>Phone code</TableCell>
              <TableCell>Flags</TableCell>
              <TableCell>ISO code</TableCell>
              {/* <TableCell>Users</TableCell> */}
              <TableCell>Admin</TableCell>
              <TableCell>GeoLock</TableCell>
              <TableCell>Maintenance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(countryData && countryData.length > 0) && countryData.map((records, index) => {
              return (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {(Number(index) + 1)}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {records.country_name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    +{records.phonecode}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Tooltip title={records.country_name}><img src={`/flags/${records.short_name.toLowerCase()}.svg`} height="16" /></Tooltip>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {records.short_name}
                  </TableCell>
                  {/* <TableCell><Checkbox {...label} id={records.id + "-status"} checked={(records.status == 'A') ? true : false} onChange={handleChange} style={{ padding: '0px' }} size="small" /></TableCell> */}
                  <TableCell><Checkbox {...label} id={records.id + "-status_admin"} checked={(records.status_admin == 'A') ? true : false} onChange={handleChange} style={{ padding: '0px' }} size="small" /></TableCell>
                  <TableCell><Checkbox {...label} id={records.id + "-status_aml"} checked={(records.status_aml == 'A') ? true : false} onChange={handleChange} style={{ padding: '0px' }} size="small" /></TableCell>
                  <TableCell><Checkbox {...label} id={records.id + "-status_maintenance"} checked={(records.status_maintenance == 'A') ? true : false} onChange={handleChange} style={{ padding: '0px' }} size="small" /></TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Create>
  );
};

export default CountrySetting;
