import * as React from 'react';
import { Fragment } from 'react';
import {
    Datagrid,
    TextField,
    TextInput,
    Filter,
    BulkDeleteButton,
    BulkExportButton,
    DateField,
    BooleanField,
    DateInput,
    SelectInput,
    useNotify, useRefresh
} from 'react-admin';
import { List, ListActions } from '@react-admin/ra-enterprise';
import { CustomBreadcrumb } from './CustomBreadcrumb';
import CustomPagination from './CustomPagination';
import { makeStyles } from '@material-ui/core/styles';
import SegmentsField from './Segments';
import CustomerLinkField from './CustomerLinkField';
import UserEmailStatus from './UserEmailStatus';
import UserActivityStatus from './UserActivityStatus';

const useStyles = makeStyles({
    capitalize: { textTransform: 'capitalize' },
    segmentColumnWidth: { width: '20%' }
});


function idRoleAllow(resource_name, action_name) {
    let return_value = 'no';
    let login_role = localStorage.getItem('role');
    if (login_role == 'admin') {
        return 'ok';
    } else {
        let allpermission = JSON.parse(localStorage.getItem("roles"));
        let roledata = [];
        if (login_role == 'accountant') {
            roledata = allpermission.accountant;
        }
        if (login_role == 'auditor') {
            roledata = allpermission.auditor;
        }
        if (login_role == 'editor') {
            roledata = allpermission.editor;
        }
        roledata.map((role_row, index) => {
            if ((role_row.resource == resource_name) && (role_row.action.indexOf(action_name) > -1)) {
                console.log('operation ok');
                return_value = 'ok';
            }
        });
        return return_value;
    }
}

const PostBulkActionButtons = ({ basePath }) => (
    <Fragment>
        {(idRoleAllow('users', 'export') == 'ok') ?
            <BulkExportButton /> : ''}
        {(idRoleAllow('users', 'delete') == 'ok') ?
            <BulkDeleteButton basePath={basePath} /> : ''}
    </Fragment>
);

const segment_choices = [
    { id: 'ordered_once', name: 'Ordered once' },
    { id: 'regular', name: 'Regular' },
    { id: 'returns', name: 'Returns' },
    { id: 'api_users', name: 'API users' },
    { id: 'referrer', name: 'Referrer' },
    { id: 'reviewers', name: 'Reviewers' },
    { id: 'hacker', name: 'Hacker' },
    { id: 'merchant', name: 'Merchant' },
    { id: 'fraudsters', name: 'Fraudsters' },
    { id: 'freeze', name: 'Freeze' }
];
const news_Choices = [{ id: 'no', name: 'No' }, { id: 'yes', name: 'Yes' }]
const Activity_Choices = [{ id: 'Yes', name: 'Online' }, { id: 'No', name: 'Offline'}]

const UsersFilter = (props) => (
    <Filter {...props}>
        <TextInput label="User Id" source="client_id" defaultValue="" />
        <TextInput label="User name" source="email" defaultValue="" />
        <SelectInput label="Email" source="latest_news" choices={news_Choices} />
        <SelectInput label="Activity" source="is_loggedin" choices={Activity_Choices} />
        <SelectInput label="Segments" source="groups" choices={segment_choices} />
        <DateInput label="Created since" source="created_at" defaultValue="" />
        <DateInput label="Creted before" source="updated_at" defaultValue="" />
    </Filter>
);

const UserList = (props) => {
    
    return (
        <List
            {...props}
            sort={{ field: 'user_type', order: 'ASC' }}
            actions={
                <ListActions
                    breadcrumb={<CustomBreadcrumb variant="actions" />}
                    exporter={(idRoleAllow('users', 'export') == 'ok') ? true : false}
                />
            }
            filters={<UsersFilter />}
            bulkActionButtons={<PostBulkActionButtons />}
            pagination={<CustomPagination />}         
            title="Users"   
        >
            <Datagrid rowClick="edit" className="segmentColumnWidth">
                <TextField source="client_id" label="User Id" />
                <CustomerLinkField source="email" label="User name" />
                <TextField source="user_type" label="User role" />
                <TextField source="email_verified" label="KYC" />
                <UserEmailStatus source="latest_news" label="Email" />
                <UserActivityStatus source="is_loggedin" label="Activity" />
                <DateField source="created_at" label="Created at" showTime />
                <SegmentsField />
            </Datagrid>
        </List>
    )
};
export default UserList;