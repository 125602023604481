import * as React from "react";
import { useState, useEffect } from "react";
import Switch from '@material-ui/core/Switch';
import { Button, PasswordInput, SelectArrayInput } from 'react-admin';
import JWT from "jsonwebtoken";
import {
    Edit,
    SelectInput,
    FormWithRedirect,
    DateInput,
    TextInput,
    SaveButton,
    DeleteButton,
    useNotify,
    useRefresh
} from 'react-admin';
import { Box, CardContent, Typography, Toolbar, Grid, InputLabel } from '@material-ui/core';
import Aside from './Aside';
import { makeStyles } from '@material-ui/core/styles';
import api_url from './ApiUrl';
import { CustomBreadcrumb } from './CustomBreadcrumb';
import { ListActions } from '@react-admin/ra-enterprise';
import FullNameField from "./FullNameField";

const choices = [{ id: 0, name: 'Pending' },
{ id: 1, name: 'Active' },
{ id: 2, name: 'Banned' }]

const type_choices = [{ id: 'a', name: 'Admin' }, { id: 'b', name: 'User' }, { id: 'c', name: 'Editor' }, { id: 'd', name: 'Accountant' }, { id: 'e', name: 'Auditor' }];

const referral_choices = [
    { id: 12, name: 'Basic' },
    { id: 15, name: 'Platinum' },
    { id: 20, name: 'Premium' },
    { id: 30, name: 'Signature' }]

const news_Choices = [{ id: 'no', name: 'No' }, { id: 'yes', name: 'Yes' }]

const segment_choices = [
    { id: 'ordered_once', name: 'Ordered once' },
    { id: 'regular', name: 'Regular' },
    { id: 'returns', name: 'Returns' },
    { id: 'api_users', name: 'API users' },
    { id: 'referrer', name: 'Referrer' },
    { id: 'reviewers', name: 'Reviewers' },
    { id: 'hacker', name: 'Hacker' },
    { id: 'merchant', name: 'Merchant' },
    { id: 'fraudsters', name: 'Fraudsters' },
    { id: 'freeze', name: 'Freeze' }
];

const useStyles = makeStyles((theme) => ({
    ownfont14: { fontSize: '14px', paddingBottom: '7px' },
    ownfont12: { fontSize: '12px' },
    reactSwitch: { pointerEvents: 'none' },
    paddingTop: { paddingTop: '0px' },
    fweight500: { fontWeight: 500 },
    googleFa: { fontSize: '1rem', fontWeight: 500 },
    buttonBlockBG: { backgroundColor: (theme.palette.type === 'light') ? 'rgb(245 245 245)' : 'rgb(83,83,83)' },
    //invoiceNumberBody: theme.palette.type === 'light' ? { backgroundColor: 'rgb(245 245 245)' } : { backgroundColor: 'rgb(83,83,83)' },
}));

const ControlledSwitches = (status_props) => {
    let user_id = status_props.id;
    let default_status = (status_props.current_status === true) ? true : false;
    let [checked, setChecked] = React.useState(default_status);
    const handleChange = (event) => {
        let login_token = localStorage.getItem('auth');
        if (event.target.checked == false) {
            setChecked(event.target.checked);
            let apitoken = JWT.sign({ url: api_url() }, "eyJhbGciOJIUzI1NiIsInR5cCI6IkpXVCJ9eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2Mj5MDIyfQSflKxwRJSMeKF2QT4fwpMeJf36POk6yJVadQsw5c", {
                algorithm: "HS512",
            });
            const request = new Request(api_url() + '/disableTwoFator', {
                method: 'POST',
                body: JSON.stringify({ user_id }),
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${login_token}`,
                    'xelxtoken': apitoken
                }),
            });
            return fetch(request)
                .then(response => {
                    if (response.status < 200 || response.status >= 300) {
                        throw new Error(response.statusText);
                    }
                })
                .catch((error) => {
                    throw new Error(error);
                });
        }
    };
    return (
        <Switch
            checked={checked}
            onClick={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
        />
    );
}

function idRoleAllow(resource_name, action_name) {
    let return_value = 'no';
    let login_role = localStorage.getItem('role');
    if (login_role == 'admin') {
        return 'ok';
    }
    let allpermission = JSON.parse(localStorage.getItem("roles"));
    let roledata = [];
    if (login_role == 'accountant') {
        roledata = allpermission.accountant;
    }
    if (login_role == 'auditor') {
        roledata = allpermission.auditor;
    }
    if (login_role == 'editor') {
        roledata = allpermission.editor;
    }
    roledata.map((role_row, index) => {
        if ((role_row.resource == resource_name) && (role_row.action.indexOf(action_name) > -1)) {
            console.log('operation ok');
            return_value = 'ok';
        }
    });
    return return_value;
}

const UserEditForm = props => {
    const notify = useNotify();
    const refresh = useRefresh();
    const classes = useStyles();
    const [currentPass, setCurrentPass] = useState('');
    const [newPass, setNewPass] = useState('');

    const addCurerntPass = (event) => {
        setCurrentPass(event.target.value);
    }
    const addNewPass = (event) => {
        setNewPass(event.target.value);
    }

    const changePassword = async () => {
        if (currentPass !== '' && newPass !== '') {
            let login_token = localStorage.getItem('auth');
            let apitoken = JWT.sign({ url: api_url() }, "eyJhbGciOJIUzI1NiIsInR5cCI6IkpXVCJ9eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2Mj5MDIyfQSflKxwRJSMeKF2QT4fwpMeJf36POk6yJVadQsw5c", {
                algorithm: "HS256",
            });
            const request = new Request(api_url() + '/updateUserPassword', {
                method: 'POST',
                body: JSON.stringify({ current_password: currentPass, new_password: newPass }),
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${login_token}`,
                    'xelxtoken': apitoken
                }),
            });
            const response = await fetch(request);
            const jsonData = await response.json();
            notify(`${jsonData.message}`, { type: 'success' });
            // if (jsonData.success == 1) {
            //     notify(`${jsonData.message}`, { type: 'success' });
            // } else {
            //     notify(`${jsonData.message}`, { type: 'error' });
            // }
            refresh();
        } else {
            notify('Password is required', { type: 'error' });
        }
    }
    return (
        <FormWithRedirect {...props} render={formProps => (
            <div>
                <CardContent>
                    <Box p="1em">
                        <Box display="flex">
                            <Box flex={2} mr="1em">
                                <Typography variant="h6" gutterBottom className={classes.fweight500}>Personal Information</Typography>

                                <Box display="flex">
                                    <Box flex={1} mr="0.5em">
                                        <TextInput disabled source="email" resource="users" type="email" fullWidth required />
                                    </Box>
                                    <Box flex={1} ml="0.5em">
                                        <TextInput disabled source="client_id" resource="users" label="User Id" fullWidth />
                                    </Box>
                                </Box>

                                <Box display="flex">
                                    <Box flex={1} mr="0.5em">
                                        <TextInput source="first_name" resource="users" label="First name" fullWidth />
                                    </Box>
                                    <Box flex={1} ml="0.5em">
                                        <TextInput source="last_name" resource="users" label="Last name" fullWidth />
                                    </Box>
                                </Box>
                                <DateInput label="Date of birth" source="date_of_birth" resource="users" />

                                <Box display="flex">
                                    <Box flex={1} mr="0.5em">
                                        <SelectInput source="country" resource="users" choices={(props.record) ? props.record.country_Choices : ''} fullWidth />
                                    </Box>
                                    <Box flex={1} ml="0.5em">
                                        <TextInput source="city" resource="users" label="City" fullWidth />
                                    </Box>
                                </Box>

                                <Box display="flex">
                                    <Box flex={1} mr="0.5em">
                                        <TextInput source="postal_code" label="Zip Code" resource="users" fullWidth />
                                    </Box>
                                    <Box flex={1} ml="0.5em">
                                        <TextInput resource="users" source="address" label="Address" multiline fullWidth />
                                    </Box>
                                </Box>


                                <Box mt="1em" />

                                <Typography variant="h6" gutterBottom className={classes.fweight500}>Corporate Information</Typography>

                                <Box display="flex">
                                    <Box flex={1} mr="0.5em">
                                        <TextInput resource="users" source="corporate_name" label="Corporate name" multiline fullWidth />
                                    </Box>
                                    <Box flex={1} ml="0.5em">
                                        <SelectInput source="corporate_country" resource="users" choices={(props.record) ? props.record.country_Choices : ''} fullWidth />
                                    </Box>
                                </Box>


                                <Box display="flex">
                                    <Box flex={1} mr="0.5em">
                                        <DateInput label="Corporate register date" source="corporate_registered_date" resource="users" fullWidth />
                                    </Box>
                                    <Box flex={1} ml="0.5em">
                                        <TextInput resource="users" source="corporate_registration_number" label="Corporate registration number" multiline fullWidth />
                                    </Box>
                                </Box>


                                <Box display="flex">
                                    <Box flex={1} mr="0.5em">
                                        <TextInput resource="users" source="corporate_state" label="Corporate state" multiline fullWidth />
                                    </Box>
                                    <Box flex={1} ml="0.5em">
                                        <TextInput resource="users" source="corporate_city" label="Corporate city" multiline fullWidth />
                                    </Box>
                                </Box>

                                <Box display="flex">
                                    <Box flex={1} mr="0.5em">
                                        <TextInput resource="users" source="corporate_zipcode" label="Corporate zipcode" multiline fullWidth />
                                    </Box>
                                    <Box flex={1} ml="0.5em">
                                        <TextInput resource="users" source="corporate_address" label="Corporate address" multiline fullWidth />
                                    </Box>
                                </Box>


                                <Box mt="1em" />
                                <Typography variant="h6" gutterBottom className={classes.fweight500}>Settings</Typography>
                                <Box display="flex">
                                    <Grid container style={{ paddingBottom: '15px' }}>
                                        <Grid item xs={6}>
                                            <Box mr="0.5em">
                                                <SelectInput source="user_status" label="Status" choices={choices} fullWidth required />
                                            </Box>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Box ml="0.5em">
                                                <SelectInput source="user_type" label="User role" choices={type_choices} fullWidth required />
                                            </Box>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Box mr="0.5em">
                                                <SelectInput source="affiliate_status" label="Referral status" choices={referral_choices} fullWidth />
                                            </Box>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Box ml="0.5em">
                                                <TextInput source="for_affiliate_use" label="Refererral Id" fullWidth disabled />
                                            </Box>
                                        </Grid>

                                        {((localStorage.getItem('role') == 'admin') && props.record.user_type == 'b') ? <>
                                            <Grid item xs={4}>
                                                <Box mr="0.5em" mt="1em">
                                                    <InputLabel className={classes.googleFa}>Google 2FA</InputLabel>
                                                </Box>
                                            </Grid>

                                            <Grid item xs={4}>
                                                {(props.record && props.record.enable_2factor !== undefined) ?
                                                    <ControlledSwitches current_status={props.record.enable_2factor} id={props.record.id} />
                                                    : ''}
                                            </Grid></> : ''}

                                    </Grid>
                                </Box>

                                <Box mt="1em" />
                                <Typography variant="h6" gutterBottom className={classes.fweight500}>Key Management</Typography>
                                <Box display="flex">
                                    <Grid container style={{ paddingBottom: '15px' }}>
                                        <Grid item xs={12}>
                                            <Box>
                                                <TextInput source="api_key" label="Public key" fullWidth disabled />
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Box>
                                                <TextInput source="secret_key" label="Secret key" fullWidth disabled />
                                            </Box>
                                        </Grid>

                                    </Grid>
                                </Box>
                                {((localStorage.getItem('role') == 'admin') && (props.record && (props.record.user_type == 'a' || props.record.user_type == 'c' || props.record.user_type == 'd' || props.record.user_type == 'e'))) ? <>
                                    <Box mt="1em" />
                                    <Typography variant="h6" gutterBottom className={classes.fweight500}>Change Password</Typography>
                                    <Box display="flex">
                                        <Grid container style={{ paddingBottom: '15px' }}>
                                            <Grid item xs={6}>
                                                <Box mr="0.5em">
                                                    <PasswordInput source="current_password" label="Current password" onChange={addCurerntPass} fullWidth />
                                                </Box>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <Box ml="0.5em">
                                                    <PasswordInput source="new_password" label="New password" onChange={addNewPass} fullWidth />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box display="flex">
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Button label="Change" variant="outlined" onClick={changePassword}></Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </> : ''}

                            </Box>

                            <Box flex={1} ml="1em">
                                <Typography variant="h6" gutterBottom className={classes.fweight500}>Stats</Typography>
                                <SelectArrayInput source="groups" resource="users" label="Segments" choices={segment_choices} fullWidth />
                                <SelectInput source="latest_news" resource="users" label="News letter" choices={news_Choices} fullWidth />
                            </Box>

                        </Box>
                    </Box>
                </CardContent>

                <Toolbar className={classes.buttonBlockBG}>
                    <Box display="flex" justifyContent="space-between" width="100%">
                        <SaveButton
                            saving={formProps.saving}
                            handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                        />
                        {
                            (idRoleAllow('users', 'delete') == 'ok') ?
                                <DeleteButton record={formProps.record} /> : ''
                        }
                    </Box>
                </Toolbar>
            </div>
        )}
        />
    )
};
const UserEditActions = (props) => (
    <ListActions  {...props} breadcrumb={<CustomBreadcrumb variant="actions" />} exporter={false} />
)

const VisitorTitle = (props) => {
    return props.record ? (
        <FullNameField record={props.record} size="32" />
    ) : null;
};

const UserEdit = props => (
    <Edit {...props}
        actions={<UserEditActions />}
        aside={<Aside />}
        title={<VisitorTitle />}
    >
        <UserEditForm />
    </Edit>
);
export default UserEdit;